import React from "react"
import Layout from "../components/layout/Layout"
import styles from "../styles/text-section.module.scss"
import ContactForm from "../components/general/contactForm/kontaktuForma"
import { Helmet } from "react-helmet"
import BenefitsSection from "../components/landing/benefits"

export default function VaziuoklesRemontas() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Modernia įranga atliekama variklio diagnostika</title>
        <meta
          name="description"
          content="Ir vėl tie keisti garsai? Važiuoklės remontas būtinas norint saugiai ekspoatuoti automobilį. Mūsų patyrę meistrai atliks važiuoklės patikrą, pasakys tikrąsias automobilio problemas ir sprendimo būdus."
        ></meta>
      </Helmet>
      <section className={`${styles.textSection} container`}>
        <h1>Važiuoklės remontas</h1>
        <p>
          Važiuoklė yra automobilio pagrindas prie kurio montuojami ratai, todėl
          važiuoklės problemos yra jaučiamos ir tiesiogiai susijusios su saugumu
          kelyje. Mūsų meistrai apžiūrės jūsų automobilį ir nustatys važiuoklės
          gedimus.
        </p>
        <p>
          Važiuoklę sudaro pakabos mazgai ir detalės: vairo mechanizmas ir ratų
          valdymas, stabdžiai, amortizatoriai ir stabilizatoriai. Kiekvienas
          važiuoklės elementas turi būti prižiūrimas. Šiuolaikinės važiuoklės
          sistemos yra pakankamai sudėtingos, todėl jų remontui reikalingi
          aukštos kvalifikacijos specialistai.
        </p>
        <p>
          Važiuoklės remontą siūloma atlikti tada, kai važiuojant nelygiu keliu
          ar dideliu greičiu automobilis ima skleisti neįprastus garsus –
          traškesį, bildesį, ūžimą, cypimą, braškėjimą ar tarškėjimą.
          Automobilio manevringumas sumažėja. Tas išryškėja posūkiuose arba
          lenkiant.
        </p>
        <p>
          Deja, ne visas važiuoklės problemas galima pastebėti kelyje. Todėl
          rekomenduojame periodiškai atlikti važiuoklės diagnostiką.
        </p>
      </section>
      <ContactForm />
      <BenefitsSection />
    </Layout>
  )
}
